import Swal from "sweetalert2";
import { fetchConToken, fetchSinToken } from "../helpers/fecth";
import { setListAsistence } from "./slices/asistenceSlices";
import { setLoginAuth, setLogout } from "./slices/authSlices";
import {
  setIdentificationCatalog,
  setServiceCatalog,
} from "./slices/catalogsSlices";
import {
  setListOrder,
  setGetOneOrder,
  setPagination,
  setSuccess,
} from "./slices/orderSlices";
import { setIsLoading } from "./slices/uiSlices";

const baseUrl = process.env.REACT_APP_API_URL;

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      let resp = await fetchSinToken(
        "users/login",
        {
          email,
          password,
        },
        "POST"
      );
      const body = await resp.json();

      if (resp.status === 200) {
        localStorage.setItem("token", body.token);
        localStorage.setItem("token-init-date", new Date().getTime());

        dispatch(
          setLoginAuth({
            email: body.email,
            id: body.userId,
            lastName: body.nombre,
            name: body.nombre,
            token: body.token,
          })
        );

        window.location = "/asistentes";
      } else {
        Swal.fire({
          title: "error",
          text: "Usuario o contraseña incorrecta",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const Logout = (token) => {
  return async (dispacth) => {
    const resp = await fetchConToken(
      "auth/logout",
      {
        token,
      },
      "PUT"
    );

    if (resp.status === 200) {
      dispacth(setLogout());
    }

    if (resp.status === 406) {
      dispacth(setLogout());
    }
    dispacth(setIsLoading(false));
  };
};

export const getServiceAreas = () => {
  return async (dispatch) => {
    try {
      let resp = await fetchSinToken("catalogos?name=Area%20de%20servicio");
      const body = await resp.json();

      dispatch(setIsLoading(false));
      if (body.success === true) {
        dispatch(setServiceCatalog(body.catalogos));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const downloadRegister = async () => {
  let resp = await fetchConToken("pedido/export");
  const body = await resp.blob();
  return body;
};

export const getEventsList = async () => {
  let resp = await fetchConToken(`event`);
  const body = await resp.json();
  return body;
};

export const getIdTypes = () => {
  return async (dispatch) => {
    try {
      let resp = await fetchSinToken("catalogos?name=identificacion");
      const body = await resp.json();
      dispatch(setIsLoading(false));
      if (body.success) {
        dispatch(setIdentificationCatalog(body.catalogos));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createOrder = (
  nombreCompleto,
  cantidadHijos,
  codigoPaisTelefono,
  telefono,
  nombreCompletoBeneficiaro,
  email,
  aniosMamaOasis,
  areaServicioId,
  emailBeneficiario,
  comprobante
) => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const data = await fileUploadFetch(`upload`, comprobante);

      if (data.status === 200) {
        const fileData = await data.json();
        const resp = await fetchSinToken(
          "pedido",
          {
            nombreCompleto,
            tipoIdentificacionId: undefined,
            identificacion: undefined,
            cantidadHijos,
            codigoPaisTelefono,
            telefono,
            email,
            nombreCompletoBeneficiaro,
            emailBeneficiario,
            aniosMamaOasis,
            areaServicioId,
            comprobantePago: fileData.url,
            aprobado: false,
            ingreso: false,
            esVisible: true,
          },
          "POST"
        );

        dispatch(setIsLoading(false));

        if (resp.status === 200) {
          Swal.fire({
            title: "¡Felicidades!",
            text: "Registro creado correctamente",
            icon: "success",
          });
          dispatch(setSuccess(true));
        } else {
          Swal.fire({
            title: "¡Error!",
            text: "Ah ocurrido un problema al registrarse",
            icon: "error",
          });
        }
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      console.log(error);
    }
  };
};

const fileUploadFetch = (endpoint, file) => {
  const url = `${baseUrl}/${endpoint}`;

  const formData = new FormData();
  formData.append("file", file);

  return fetch(url, {
    method: "POST",
    body: formData,
  });
};

export const getAllOrders = () => {
  return async (dispatch) => {
    try {
      let resp = await fetchConToken("pedido");
      const body = await resp.json();

      if (body.success === true) {
        dispatch(setIsLoading(false));
        dispatch(setListOrder(body.pedidos));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getOneOrderById = (id) => {
  return async (dispatch) => {
    try {
      let resp = await fetchConToken(`pedido/${id}`);
      const body = await resp.json();

      if (body.success === true) {
        dispatch(setGetOneOrder(body.pedido));
        dispatch(setIsLoading(false));
      }
    } catch (error) {
      // console.log(error)
    }
  };
};

export const searchOrder = (search) => {
  return async (dispatch) => {
    try {
      let resp = await fetchConToken(`pedido?search=${search}`);

      const body = await resp.json();

      dispatch(setIsLoading(false));

      if (resp.status === 200) {
        dispatch(setListOrder(body.pedidos));
        dispatch(setPagination(body.paginationData));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const paginationOrders = (pageSize, pageNumber) => {
  return async (dispatch) => {
    try {
      let resp = await fetchConToken(
        `pedido?page=${pageNumber}&size=${pageSize}`
      );

      const body = await resp.json();

      if (resp.status === 200) {
        dispatch(setIsLoading(false));
        dispatch(setListOrder(body.pedidos));
        dispatch(setPagination(body.paginationData));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const aproveOrder = (pedidoId, aprobado) => {
  return async (dispatch) => {
    try {
      let resp = await fetchConToken(
        `pedido/${pedidoId}`,
        {
          aprobado,
        },
        "PATCH"
      );

      const data = await resp.json();

      dispatch(setIsLoading(false));

      if (data.success === true) {
        Swal.fire({
          title: "¡Felicidades!",
          text: data.msg,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(getAllOrders());
          }
        });
      } else {
        Swal.fire({
          title: "¡Error!",
          text: "Ah ocurrido un problema al registrarse",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const resendOrderById = (id) => {
  return async (dispatch) => {
    try {
      let resp = await fetchConToken(`pedido/${id}/notification`, {}, "POST");

      if (resp.status === 200) {
        dispatch(setIsLoading(false));
        Swal.fire({
          title: "¡Éxito!",
          text: "Correo reenviado exitosamente",
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getAllAsistences = ({ currentPage = 0, size = 10 }) => {
  return async (dispatch) => {
    try {
      let resp = await fetchConToken(
        `assistances?filter=${JSON.stringify({
          limit: size,
          skip: size * currentPage,
          order: ["name ASC", "lastName ASC"],
        })}`
      );
      const body = await resp.json();
      dispatch(setIsLoading(false));

      if (resp.status === 200) {
        dispatch(setListAsistence(body));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const searchAsistence = ({ currentPage = 0, name, size = 10 }) => {
  return async (dispatch) => {
    try {
      let resp = await fetchConToken(
        `assistances?filter=${encodeURIComponent(
          JSON.stringify({
            limit: size,
            where: {
              or: [
                { name: { like: `%${name}%`, options: "i" } },
                { lastName: { like: `%${name}%`, options: "i" } },
                { email: { like: `%${name}%`, options: "i" } },
                {
                  pid: { like: `%${name}%`, options: "i" },
                },
                {
                  phone: { like: `%${name}%`, options: "i" },
                },
              ],
            },
          })
        )}`
      );
      const body = await resp.json();

      dispatch(setIsLoading(false));

      if (resp.status === 200) {
        dispatch(setListAsistence(body));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const entranceByQrCode = (id, eventId) => {
  return async () => {
    try {
      const resp = await fetchConToken(
        `registrations/${id}/event/${eventId}/assisted/1`,
        "PATCH"
      );

      if (resp.status === 200) {
        const body = await resp.json();
        Swal.fire({
          title: "¡Éxito!",
          text: `Se ha ingresado a ${body.name.toUpperCase()} ${body.lastName.toUpperCase()} al evento`,
          icon: "success",
          confirmButtonText: "Siguiente",
        });
      } else {
        if (resp.status === 404) {
          Swal.fire({
            title: "¡Error!",
            text: "Este participante no existe.",
            icon: "error",
          });
        } else {
          Swal.fire({
            title: "¡Error!",
            text: "Id no detectado",
            icon: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateEventAssistanceByUser = async ({
  participantId,
  eventId,
  assisted,
}) => {
  let resp = await fetchConToken(
    `assistances/${participantId}/event/${eventId}/assisted/${assisted}`,
    "PATCH"
  );

  return resp;
};

export const getAllAsistencesAllEvents = async () => {
  let resp = await fetchConToken(`assistances/totals`);
  const body = await resp.json();

  if (resp.status === 200) {
    return body;
  }
};

export const countAllAsistences = async ({ currentDate }) => {
  let resp = await fetchConToken(`assistances/count`);
  const body = await resp.json();
  if (resp.status === 200) {
    return body;
  }
};

export const asistencesExportExcel = async () => {
  let resp = await fetchConToken(`assistances/export`);
  const body = await resp.blob();
  if (resp.status === 200) {
    return body;
  }
};
