import { useEffect, useState } from "react";
import { QrReader } from "@blackbox-vision/react-qr-reader";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import hashids from "hashids";

import HeaderManager from "../../../components/Header/HeaderManager";
import SideBar from "../../../components/SideBar";
import { entranceByQrCode } from "../../../redux/thunks";

export const Scanner = () => {
  const dispatch = useDispatch();

  const [datas, setDatas] = useState("");

  const handleScan = (result) => {
    if (!!result) {
      setDatas(result?.text);
    }
  };

  useEffect(() => {
    const checkAndRequestCameraPermission = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: "camera",
        });

        if (permissionStatus.state === "granted") {
          // Camera access is already granted
        } else if (permissionStatus.state === "prompt") {
          // Request camera access
          const granted = await permissionStatus.request();
          if (granted === "granted") {
            // Camera access granted
          } else {
            // Camera access denied
          }
        } else {
          // Camera access denied
        }
      } catch (error) {
        console.error("Error checking camera permissions:", error);
      }
    };

    checkAndRequestCameraPermission();
  }, []);

  useEffect(() => {
    if (datas !== "") {
      const hashidsDecoder = new hashids(
        process.env.REACT_APP_HASH_SALT,
        parseInt(process.env.REACT_APP_HASH_SIZE)
      );
      const recordId = hashidsDecoder.decode(datas);
      try {
        if (Array.isArray(recordId) && recordId.length === 1) {
          const eventIds = {
            '2023-11-16': 1,
            '2023-11-17': 8,
            '2023-11-18': 9,
          };
          const currentEventId = eventIds[new Date(new Date().getTime() - 6 * 60 * 60 * 1000).toISOString().split('T')[0]];
          dispatch(entranceByQrCode(recordId[0], currentEventId));
        } else {
          throw new Error("QR no válido");
        }
      } catch (error) {
        Swal.fire({
          title: "¡Error!",
          text: "QR no válido",
          icon: "error",
        });
      }
    }
  }, [dispatch, datas]);

  return (
    <>
      <HeaderManager />
      <SideBar />
      <hr />
      <div className="bg-white">
        <div className="mx-auto w-9/12 pt-28 text-center">
          <h1 className="text-6xl text-titleTextColor font-bold">
            Escanear código Qr
          </h1>
          <div className="w-full flex justify-center items-center">
            <div className="w-full">
              <QrReader
                constraints={{
                  facingMode: "environment",
                }}
                scanDelay={500}
                onResult={handleScan}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
